import { createSearch, System } from '@/modules/system'
import store from '@/plugins/store'
import $app from '@/plugins/modules';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IDamage, IDamageSearch, IExternalMessage, IFeeHistory, IFeeHistorySearch, ILaboratory, ILabsSearch, IManipulationSearch, IManipulationType, IMessagesFilter, IMessagesSearch, IResearch, IResearches, ISalarySearch, ISalarySettings } from './types'
import { IEntityOperation, IPagination } from '../system/types';

const $system = $app.module(System);

@Module({ dynamic: true, store, name: 'clinic' })
export default class ClinicStore extends VuexModule {
  private messagesSearch: IMessagesSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        filter: '',
        state: null,
        patientId: null,
        from: new Date().native(false),
        to: new Date().native(false)
      }
    )
  }

  private manipulationsSearch: IManipulationSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: '',
        code: '',
        name: '',
        groupName: ''
      }
    )
  }

  private damagesSearch: IDamageSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: '',
        code: '',
        name: '',
        groupName: ''
      }
    )
  }

  private salarySearch: ISalarySearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        userId: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
    )
  }

  private labsSearch: ILabsSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        search: ''
      }
    )
  }

  private researches: IResearches = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        inactive: false,
        from: new Date().fromMonthStart().native(false),
        to: new Date().native(false)
      }
    )
  }

  private feeHistories: IFeeHistorySearch = {
    items: [],
    filter: createSearch(
      0, 0, 'FeeHistoryDate', true,
      {
        userId: null,
        from: new Date().fromMonthStart().native(false),
        to: new Date().native(false)
      }
    )
  }

  get MessagesSearch(): IMessagesSearch {
    return this.messagesSearch
  }

  get ManipulationSearch(): IManipulationSearch {
    return this.manipulationsSearch
  }

  get DamageSearch(): IDamageSearch {
    return this.damagesSearch
  }

  get SalarySearch(): ISalarySearch {
    return this.salarySearch;
  }

  get LabsSearch(): ILabsSearch {
    return this.labsSearch
  }

  get Researches(): IResearches {
    return this.researches
  }

  get FeeHistories(): IFeeHistorySearch {
    return this.feeHistories
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  setMessagesSearch(value: IMessagesSearch) {
    this.messagesSearch = value;
  }

  @Mutation
  changeMessage(value: IExternalMessage) {
    const index = this.messagesSearch.items.findIndex(m => m.messageId === value.messageId)
    if (index !== -1) {
      this.messagesSearch.items.splice(index, 1, value);
    }
  }

  @Mutation
  setManipulationSearch(value: IManipulationSearch) {
    this.manipulationsSearch = value;
  }

  @Mutation
  setDamageSearch(value: IDamageSearch) {
    this.damagesSearch = value;
  }

  @Mutation
  setSalarySearch(value: ISalarySearch) {
    this.salarySearch = value;
  }

  @Mutation
  setLabsSearch(value: ILabsSearch) {
    this.labsSearch = value;
  }

  @Mutation
  setResearches(value: IResearches) {
    this.researches = value;
  }

  @Mutation
  setFeeHistories(value: IFeeHistorySearch) {
    this.feeHistories = value;
  }

  @Mutation
  changeManipulation(oper: IEntityOperation<IManipulationType>) {
    let index = -1;
    for (let i = 0; i < this.manipulationsSearch.items!.length; i++) {
      if (this.manipulationsSearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.manipulationsSearch.items = [oper.value].concat(this.manipulationsSearch.items || []);
    } else if (oper.type === 'store') {
      this.manipulationsSearch.items.splice(index, 1, oper.value);
    } else {
      this.manipulationsSearch.items.splice(index, 1);
    }
  }

  @Mutation
  changeDamage(oper: IEntityOperation<IDamage>) {
    let index = -1;
    for (let i = 0; i < this.damagesSearch.items!.length; i++) {
      if (this.damagesSearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.damagesSearch.items = [oper.value].concat(this.damagesSearch.items || []);
    } else if (oper.type === 'store') {
      this.damagesSearch.items.splice(index, 1, oper.value);
    } else {
      this.damagesSearch.items.splice(index, 1);
    }
  }

  @Mutation
  changeSalary(oper: IEntityOperation<ISalarySettings>) {
    let index = -1;
    for (let i = 0; i < this.salarySearch.items!.length; i++) {
      if (
        this.salarySearch.items[i].userId === oper.value.userId &&
        this.salarySearch.items[i].month === oper.value.month &&
        this.salarySearch.items[i].year === oper.value.year
      ) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.salarySearch.items = [oper.value].concat(this.salarySearch.items || []);
    } else if (oper.type === 'store') {
      this.salarySearch.items.splice(index, 1, oper.value);
    } else {
      this.salarySearch.items.splice(index, 1);
    }
  }

  @Mutation
  changeLab(oper: IEntityOperation<ILaboratory>) {
    let index = -1;
    for (let i = 0; i < this.labsSearch.items!.length; i++) {
      if (this.labsSearch.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.labsSearch.items = [oper.value].concat(this.labsSearch.items || []);
    } else if (oper.type === 'store') {
      this.labsSearch.items.splice(index, 1, oper.value);
    } else {
      this.labsSearch.items.splice(index, 1);
    }
  }

  @Mutation
  changeResearch(oper: IEntityOperation<IResearch>) {
    let index = -1;
    for (let i = 0; i < this.researches.items!.length; i++) {
      if (this.researches.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.researches.items = [oper.value].concat(this.researches.items || []);
    } else if (oper.type === 'store') {
      this.researches.items.splice(index, 1, oper.value);
    } else {
      this.researches.items.splice(index, 1);
    }
  }

  @Mutation
  changeFeeHistory(oper: IEntityOperation<IFeeHistory>) {
    let index = -1;
    for (let i = 0; i < this.feeHistories.items!.length; i++) {
      if (this.feeHistories.items[i].id === oper.value.id) {
        index = i;
        break;
      }
    }

    if (index === -1 && oper.type === 'store') {
      this.feeHistories.items = [oper.value].concat(this.feeHistories.items || []);
    } else if (oper.type === 'store') {
      this.feeHistories.items.splice(index, 1, oper.value);
    } else {
      this.feeHistories.items.splice(index, 1);
    }
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action({ rawError: true })
  async findMessages(pagination: IPagination) {
    this.setMessagesSearch(
      await $system.$module.find('/api/ex-messages/search', this.messagesSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async loadMessages(filter: IMessagesFilter): Promise<Array<IExternalMessage>> {
    let messagesSearch: IMessagesSearch = {
      items: [],
      filter
    }
    const pagination: IPagination = {
      itemsPerPage: filter.itemsPerPage,
      page: 1,
      sortBy: filter.sortBy,
      sortDesc: filter.sortDesc,
      append: false

    }
    messagesSearch = await $system.$module.find('/api/ex-messages/search', messagesSearch, pagination)

    return messagesSearch.items;
  }

  @Action({ rawError: true })
  async storeMessage(value: IExternalMessage) {
    value = await $app.post('/api/ex-messages', value);
    this.changeMessage(value);
  }

  @Action({ rawError: true })
  async findManipulations(pagination: IPagination) {
    this.setManipulationSearch(
      await $system.$module.find('/api/clinics/manipulations/search', this.manipulationsSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeManipulation(value: IManipulationType) {
    value = await $app.post('/api/clinics/manipulations', value);
    this.changeManipulation({ type: 'store', value });
  }

  @Action({ rawError: true })
  async removeManipulation(value: IManipulationType) {
    await $app.delete('/api/clinics/manipulations/' + value.id);
    this.changeManipulation({ type: 'remove', value });
  }

  @Action({ rawError: true })
  async findDamages(pagination: IPagination) {
    this.setDamageSearch(
      await $system.$module.find('/api/clinics/damages/search', this.damagesSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeDamage(value: IDamage) {
    value = await $app.post('/api/clinics/damages', value);
    this.changeDamage({ type: 'store', value });
  }

  @Action({ rawError: true })
  async removeDamage(value: IDamage) {
    await $app.delete('/api/clinics/damages/' + value.id);
    this.changeDamage({ type: 'remove', value });
  }

  @Action({ rawError: true })
  async findSalary(pagination: IPagination) {
    this.setSalarySearch(
      await $system.$module.find('/api/clinics/salary/search', this.salarySearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeSalary(value: ISalarySettings) {
    value = await $app.post('/api/clinics/salary', value);
    this.changeSalary({ type: 'store', value });
  }

  @Action({ rawError: true })
  async recalcSalary(value: ISalarySettings) {
    await $app.patch('/api/clinics/salary', value);
  }

  @Action({ rawError: true })
  async removeSalary(value: ISalarySettings) {
    await $app.delete('/api/clinics/salary/' + value.year + '/' + value.month + '/' + value.userId);
    this.changeSalary({ type: 'remove', value });
  }

  @Action({ rawError: true })
  async findLabs(pagination: IPagination) {
    this.setLabsSearch(
      await $system.$module.find('/api/clinics/labs/search', this.labsSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async storeLab(value: ILaboratory) {
    value = await $app.post('/api/clinics/labs', value);
    this.changeLab({ type: 'store', value });
  }

  @Action({ rawError: true })
  async removeLab(value: ILaboratory) {
    await $app.delete('/api/clinics/labs/' + value.id);
    this.changeLab({ type: 'remove', value });
  }

  @Action({ rawError: true })
  async findResearch(pagination: IPagination) {
    this.setResearches(
      await $system.$module.find('/api/clinics/researches/search', this.researches, pagination)
    );
  }

  @Action({ rawError: true })
  async storeResearch(value: IResearch): Promise<IResearch> {
    const cleaned: IResearch = $app.clone(value);

    cleaned.patientName = '';
    cleaned.patientPhone = '';
    cleaned.patientPhoto = null;

    const updated: IResearch = await $app.post('/api/clinics/researches', cleaned);
    value.id = updated.id;

    this.changeResearch({ type: 'store', value });
    return value;
  }

  @Action({ rawError: true })
  async setResearchState(value: IResearch) {
    const cleaned: IResearch = $app.clone(value);

    cleaned.patientName = '';
    cleaned.patientPhone = '';
    cleaned.patientPhoto = null;

    await $app.patch('/api/clinics/researches/' + value.id, cleaned);
    this.changeResearch({ type: 'store', value });
  }

  @Action({ rawError: true })
  async removeResearch(value: IResearch) {
    await $app.delete('/api/clinics/researches/' + value.id);
    this.changeResearch({ type: 'remove', value });
  }

  @Action({ rawError: true })
  async findFeeHistory(pagination: IPagination) {
    this.setFeeHistories(
      await $system.$module.find('/api/clinics/fees/history/search', this.feeHistories, pagination)
    );
  }

  @Action({ rawError: true })
  async storeFeeHistory(value: IFeeHistory) {
    value = await $app.post('/api/clinics/fees/history', value);
    this.changeFeeHistory({ type: 'store', value });
  }

  @Action({ rawError: true })
  async removeFeeHistory(value: IFeeHistory) {
    await $app.delete('/api/clinics/fees/history/' + value.id);
    this.changeFeeHistory({ type: 'remove', value });
  }
}
